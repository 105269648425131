import React from 'react';
import './App.scss';
import {BrowserRouter as Router, Switch, Route,} from 'react-router-dom'
import PageNotFound from './pages/page-not-found/page-not-found';
import Contact from './pages/contact/contact';
import Main from './pages/main/main';
import WhatWeDo from './pages/what-we-do/what-we-do';
import WhoWeAre from './pages/who-we-are/who-we-are';
import Services from './pages/services/services';
import Landing from './pages/landing/landing';



function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/landing">
            <Landing/>
          </Route>
          <Route path="/contact">
            <Contact/>
          </Route>
          <Route exact path="/who-we-are">
            <WhoWeAre/>
          </Route>
          <Route exact path="/what-we-do">
            <WhatWeDo/>
          </Route>
          <Route exact path="/services">
            <Services/>
          </Route>
          <Route exact path="/">
            <Main/>
          </Route>
          <Route path="**">
            <PageNotFound/>
          </Route>
        </Switch>
        {/* <Menu/> */}
      </Router>
    </div>
  );
}

export default App;
