import React, {Component} from 'react'
import './landing.scss'

class Landing extends Component {
	render(){
		return(
			<div className="landing-container">
				<div>
					<div className="circle-container">
						<div className="box"></div> 
						<svg  className="shadow circle" height="550" width="550" viewBox="0 0 550 550" transform="rotate(-90 deg)">
							<linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
								<stop offset="0%"   stopColor="#0b526e"/>
								<stop offset="50%"  stopColor="#0b526e"/>
								<stop offset="100%" stopColor="#0b526e"/>
							</linearGradient>
 							<circle cx="270" cy="270" r="160" stroke="url(#linear)" strokeWidth="7" fill="#252525"/>
						</svg>
						<div className="circle-shadow"></div>
					</div>
					<div className = "cursor">
					</div>					
					<div className="landing-title">
						<h7>DRAG LEFT OR RIGHT</h7>
					</div>
				</div>
			</div>
			
		)
	}
}

export default Landing