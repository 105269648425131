import React from 'react';
import './main.scss'
import WhoWeAre from '../who-we-are/who-we-are';
import OurTeam from '../our-team/our-team';
import OurWork from '../our-work/our-work';
import Contact from '../contact/contact'
import Menu from '../../components/menu/menu'
import WhatWeDo from '../what-we-do/what-we-do'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Landing from '../landing/landing';

class Main extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			index: 0,
			isLoading: false
		}
	}
	componentDidMount() {
		console.log('did')
		setTimeout( () => {
			this.setState({
				isLoading: false
			})
		}, 5000)
	}

	componentWillMount () {
		console.log('will')
		this.setState({
			isLoading: false
		})
	}

	render(){
		//Gets current index of slider
		let afterChangeHandler = (currentSlide) => {
			console.log(currentSlide)
			this.setState({
				index: currentSlide
			})
			currentSlide = this.state.index
		}
		let changeSlide=(index)=>{
			this.slider.slickGoTo(index)
			this.setState({
				index: index
			})
			index = this.state.index
		}

		var settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		  };


		return(
			<div style={{height:'100%'}}>
				{this.state.isLoading === false && 
					<div className="main-loading-container">
						<Slider dots={false} arrows={false} ref={slider => (this.slider = slider)} afterChange={afterChangeHandler} className="slider-container"  {...settings}>
							<div className="landing-slider">
								<Landing/>
							</div>
							<div className="who-slider">
								<WhoWeAre/>
							</div>
							<div className="what-slider">
								<WhatWeDo/>
							</div>
							{/* <div className="our-team-slider">
								<OurTeam/>
							</div> */}
							{/* <div className="our-work-slider">
								<OurWork/>
							</div> */}
							<div className="contact-slider">
								<Contact/>
							</div>
						</Slider>
						<Menu index={this.state.index} onChangeSlide={changeSlide} />
					</div>
				}
				{this.state.isLoading === true && 
					<div className="landing-loading-container">
						<Landing/>
					</div>
				}
			</div>
		)
	}
}

export default Main