import React, { Component } from 'react'
import './who-we-are.scss'
import boardLoop from "../../assets/background-images/boardLoop.mp4"

class WhoWeAre extends Component {
	state = {
		open: false,
		open1: false,
		open2: false,
		overlay: false
	}

	render(){
		let handleClick = (e) => {
			e.preventDefault();
			console.log('Clicked')
			if(this.state.open){
				this.setState({
					open: false,
					overlay: false,
				})
			}else{
				this.setState({
					open: true,
					open1: false,
					open2:false,
					overlay: true,

				})
			}
			console.log(this.state.open)
		}

		let handleClick2 = (e) => {
			e.preventDefault()
			if(this.state.open1){
				this.setState({
					open1: false,
					overlay: false,
				})
			}else{
				this.setState({
					open: false,
					open1: true,
					open2:false,
					overlay: true,
				})
			}
		}

		let handleClick3 = (e) => {
			e.preventDefault()
			if(this.state.open2){
				this.setState({
					open2: false,
					overlay: false,
				})
			}else{
				this.setState({
					open: false,
					open1: false,
					open2: true,
					overlay: true,
				})
			}
		} 

		return(
			<div className="who-container">
				<div className={(this.state.overlay ?"open" :'')} id="overlay">
				</div>
				<div>
					<video playsInline autoPlay muted loop className="who-video" ><source src={boardLoop} type="video/mp4"></source>  </video>
				</div>
					<div className="who-sub-container">
						<div className="content-container">
							<div onClick={handleClick}>
								{!this.state.open &&
									<h1 className="who-content-think">THINK</h1>
								}
								{this.state.open &&
										<div className="who-open-think">
											<h1 style={{backgroundColor: '#000000'}}>
											Our team can create architecture to solve any problem; so all
											you need is a vision. We take care of all the planning for you, or
											if you already have a design, we ensure that nothing is left out.
											</h1 >
											<div className="cross"></div>
										</div>
								}
							</div>
							<div onClick={handleClick2}>
								{!this.state.open1 &&
									<h1 className="who-content">SOLVE</h1>
								}
								{this.state.open1 && 
									<div className="who-open-content">
									<h1 style={{backgroundColor: '#000000'}}>
									As trailblazer of an emerging technology, building a solution
									often means doing something no one else has done before.
									This means you will need the right team to pioneer solutions in
									the face of new challenges, not just replicate other frameworks.
									</h1>
									<div className="cross"></div>
								</div>
								}
							</div>
							<div onClick={handleClick3}>
								{!this.state.open2 &&
									<h1 className="who-content">THRIVE</h1>
								}
								{this.state.open2 && 
									<div className="who-open-content">
									<h1 style={{backgroundColor: '#000000'}}>
									Your projects' success will be driven by the architecture and
									security of the project. All processes are undertaken with the
									long term success of the platform in mind.

									</h1 >
									<div className="cross"></div>
								</div>
								}
							</div>
						</div>
						<div className="who-title">
							<hr/>
							<h7>WHO WE ARE</h7>
							<hr/>
						</div>
					</div>
			</div>
		)
	}
}

export default WhoWeAre

