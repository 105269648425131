import React, { Component } from 'react'
import './page-not-found.scss'

class PageNotFound extends Component {
	render(){
		return(
			<div className="page-not-found-container">
				<div>
					<div className="page-not-found-title">
						<h1 className="page-not-title">Page Not Found</h1>
					</div>
				</div>
			</div>
		)
	}
}

export default PageNotFound