import React, {Component} from 'react'
import './services.scss'

class Services extends Component {
  render(){
    return(
      ''
    )
  }
}

export default Services