import React, { Component } from 'react'
import './what-we-do.scss'
import logo from '../../assets/logos/swamplogo.png'

class WhatWeDo extends Component {
	state = {
		clicked: false,
		open1:false
	}

	render(){
		let handleClick = (type=false) => {
			console.log("type",type)
			if(!this.state.clicked){
				this.setState({
					clicked: true,
					activeView: (type== this.state.activeView ? '': type)
				})
			}else{
				this.setState({
					clicked: false,
				})
			}
			if(this.state.clicked){
				setTimeout(() => {
					this.setState({
						activeView: (type== this.state.activeView ? '': type)
					})
				}, 300);
			}
		}
		let handleClick2 = (e) => {
			e.preventDefault()
			 this.setState({
			 	open1: !this.state.open1
			 })
		}
		return(
			<div className="what-container">
				<div className={"what-title-container  " + (this.state.clicked ? 'hidden':'')}>
					<div className="what-logo-container" onClick={()=>handleClick("digital")}>
						<div className={"line-container " + (this.state.activeView && this.state.activeView !== "digital" ? 'hide':'')}>
							<span><h1 className="services-logo">Return</h1></span>
							<span></span>
						</div>
						<h2>Digital services</h2>
						<img className="what-logo" src={logo} alt=""/>
					</div>
					<div className="what-logo-container2" onClick={()=>handleClick("blockchain")}>
						<div className={"line-container2 " + (this.state.activeView && this.state.activeView !== "blockchain" ? 'hide':'')}>
							<span><h1 className="services-logo">Return</h1></span>
							<span></span>
						</div>
						<h4>BlockChain</h4>
						<img className="what-logo" src={logo} alt=""/>					
					</div>
					<div className="what-content-container">
						<h1 className="what-title">WHAT WE DO</h1>
						<h3 className="what-content">Tap a logo to know more</h3>
					</div>
				</div>
				<div className={"services-content-container " + (this.state.clicked ? "show":'')}>
				<div>
				</div>
				<span className="vl"></span>
				<div className={"services-content " + (this.state.activeView !== "digital" ? 'hide':'')}>
					<h6 className="services-title">DIGITAL SERVICES</h6>
					<p className="services-desc">
					With innovation and technology moving a mile a minute, it isn't hard to get left in the dark abyss
					That's why hiring a development team who keeps you in the loop is so important. At Silicon Swamp, 
					we pride ourselves on being up to date with the latest bleeding edge technology.
					</p>
					<div className="services-link">
					 <p onClick={handleClick2}>White Paper</p>
					 <span className="arrow">&#8594;</span>
						<div className={"pdf " + (this.state.open1 ? 'pdf-open':'pdf-close')} >
							<iframe title="whitePages" src="https://siliconswamp.io/files/Swamp-White-Pages.pdf"></iframe>
							<div onClick={handleClick2} id="cross"></div>
						</div>
					</div>
				</div>
				<div className={"services-content " + (this.state.activeView !== "blockchain" ? 'hide':'')}>
					<h6 className="services-title">BLOCKCHAIN</h6>
					<p className="services-desc">
					Our experienced blockchain developers are exactly what you
					need to get your crypto project off the ground. Whether it's
					creating an asset or adding functionality to your existing one,
					we've got you covered.
					</p>
					<div className="services-link">
					 <p onClick={handleClick2}>White Paper</p>
					 <span className="arrow">&#8594;</span>
						<div className={"pdf " + (this.state.open1 ? 'pdf-open':'pdf-close')} >
							<iframe title="whitePages" src="https://siliconswamp.io/files/Swamp-White-Pages.pdf"></iframe>
							<div onClick={handleClick2} id="cross"></div>
						</div>
					</div>
				</div>
			</div>
		</div>	

		)
	}
}

export default WhatWeDo