import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Helmet } from 'react-helmet'

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
          <title>Silicon Swamp | Software Solutions </title>
          <meta name="description" content="Silicon Swamp is a blockchain development company building projects in the blockchain and cryptocurrencies industry.
              We are offering full development cycle for decentralized applications, as well as tokenization and technology consulting."/>
        </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
