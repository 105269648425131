/* eslint-disable jsx-a11y/heading-has-content */
import React, { Component} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, } from '@fortawesome/free-brands-svg-icons'
import './menu.scss'
import MenuSlider from '../menu-slider/menu-slider';
import hex from "../../assets/logos/hex.png";
import whathex from "../../assets/logos/what-hex.png";
import whohex from "../../assets/logos/who-hex.png"
import contacthex from "../../assets/logos/contact-hex.png"
import workhex from "../../assets/logos/workhex.png"


class Menu extends Component {
	state = {
		menuOpen: false,
		menuIndex:1,
		pages:[
			{
				title:"WHO WE ARE",
				img:whohex,
			},
			{
				title:"WHAT WE DO",
				img:whathex,
			},
			// {
			// 	title:"OUR WORK",
			// 	img:workhex,
			// },
			{
				title:"CONTACT US",
				img:contacthex,
			}
		],
	}
	changeSlide = (index,closeMenu=true)=> {
		this.props.onChangeSlide(index)
		this.setState({
			...this.state,
			menuIndex: index,
		})
		if(closeMenu){
			setTimeout(() => {
			this.setState({
				menuOpen:false
			})
		}, 450)
	}
	}
	callbackFunction = (childIndex) => {
		this.props.index = childIndex
		console.log('chicdinced' + childIndex)
	}
	render(){
		let handleClick = (e) => {
			e.preventDefault()
			if(this.state.menuOpen){
				this.setState({
					menuOpen: false
				})
			}else{
				this.changeSlide(this.props.index,false)
				this.setState({
					menuOpen: true
				})
			}
		}
		return (
			<div className="menu-container">
				<div className= {"menu-open-container "+(this.state.menuOpen ?"open" :'') }>
					<div className="menu-open-content">
						<div className="menu-slider-container" id="menuslider">
							{this.state.pages.map((el, i)=>{
								return ( 
								<div key={i+1} className={"hex hex"+(i+1)+" "+(this.state.menuIndex===(i+1)?"active" :'') } onClick={()=>this.changeSlide(i+1)}>
									<img onDragStart={(e)=>e.preventDefault()} className="color" src={el.img} alt={el.title}/>
									<img onDragStart={(e)=>e.preventDefault()}  className="default" src={hex} alt={el.title}/>
									<b>0{i+1}</b>
									<strong>{el.title}</strong>
								</div>
								)
					        })}
							<MenuSlider parentCallback={this.callbackFunction} index={this.props.index}/>
						</div>	
					</div> 
				{!this.state.menuOpen && 
				<div className="index-container"> 
						{this.state.pages.map((el, i)=>{
							return (<h1 key={i+1} className={"index-box " +(this.props.index===(i+1) ? "active":'')}></h1>)
						})}
				</div>}
					{this.state.menuOpen !== true &&
					<div className="menu-title-container">
						<h1 onClick={handleClick} className="menu-title">MENU</h1>
					</div>
					}
					{this.state.menuOpen === true &&
					<div className="menu-title-container-open">
						<h1 id="menu-title">Silicon Swamp</h1>
						<div className="open">
							<div className="index-container-open" >
								{this.state.pages.map((el, i)=>{
								return (<h1 key={i+1} className={"index-box-open " +(this.props.index===(i+1) ? "active":'')}></h1>)
						    	})}
							</div>
						</div>
						<div className="menu-title-icons open">
							<FontAwesomeIcon onClick={() => window.location="https://www.facebook.com/siliconswamp/" } className="icon" icon={faFacebook}/>
							
							<FontAwesomeIcon onClick={() => window.location="https://twitter.com/SiliconSwampLLC"} className="icon" icon={faTwitter}/>
						</div>
					</div>
					}
				</div>
			</div>
		)
	}
}

export default Menu