import React, { Component } from 'react'
import './contact.scss'

class Contact extends Component {
	render(){
		return (
			<div className="contact-container">
				<div>
					<div className="contact-title">
						<p>SAY HELLO TO SILICON SWAMP</p>
					</div>
					<div className="contact-info-container">
						<div className="cell-container">
							<h3 className="text">Call Us At</h3>
							<h3 className="cell-number">+18557926746</h3>
						</div>
						<div className="vl"></div>
						<div className="email-container">
							<h3 className="text">Email Us At</h3>
							<h3 className="email">hello@siliconswamp.io</h3>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Contact