import React, { Component } from 'react'
import Hexagon from 'react-hexagon'
import './our-team.scss'
import jacksonHeadshot from '../../assets/teamHeadshots/jacksonHeadshot.jpg'
import allisonHeadshot from '../../assets/teamHeadshots/allisonHeadshot.jpg'
import fabricioHeadshot from '../../assets/teamHeadshots/fabricioHeadshot.jpg'
import joshHeadshot from '../../assets/teamHeadshots/joshHeadshot.jpg'
import kateyHeadshot from '../../assets/teamHeadshots/kateyHeadshot.jpg'
import mathewHeadshot from '../../assets/teamHeadshots/mathewHeadshot.jpg'
import justinHeadshot from '../../assets/teamHeadshots/justinHeadshot.jpg'
import nickHeadshot from '../../assets/teamHeadshots/nickHeadshot.jpg'

class OurTeam extends Component {
	render(){
		return(
			<div className="our-team-container">
				<div>
					<div className="our-team-content">
					<Hexagon
					style={{stroke: '#30bbcb'}}
					className="hexagon jackson"
					backgroundImage={jacksonHeadshot}
					/>
					<Hexagon
					style={{stroke: '#30bbcb'}}
					backgroundImage={allisonHeadshot}
					className="hexagon allison"
					/>
					<Hexagon
					style={{stroke: '#30bbcb'}}
					backgroundImage={fabricioHeadshot}
					className="hexagon fabricio"
					/>
					<Hexagon
					style={{stroke: '#30bbcb'}}
					backgroundImage={joshHeadshot}
					className="hexagon josh"
					/>
					<Hexagon
					style={{stroke: '#30bbcb'}}
					className="hexagon justin"
					backgroundImage={justinHeadshot}
					/>
					<Hexagon
					style={{stroke: '#30bbcb'}}
					backgroundImage={mathewHeadshot}
					className="hexagon matthew"
					/>
					<Hexagon
					style={{stroke: '#30bbcb'}}
					backgroundImage={kateyHeadshot}
					className="hexagon katey"
					/>
					<Hexagon
					style={{stroke: '#30bbcb'}}
					backgroundImage={nickHeadshot}
					className="hexagon nick"
					/>
					</div>
				</div>
			</div>
		)
	}
}

export default OurTeam