import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';


export default class MenuSlider extends Component {
	constructor(props){
		super(props)
		this.state = {
			index: 0
		}
	}

	componentDidMount(){
		this.setState({
			index: this.props.index
		})
	}
	render() {

		var settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		  };
		return(
			<div className="menu-container">
			
				</div>
				
		)
	}
}