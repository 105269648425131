import React, { Component, useState } from 'react'
import './our-work.scss'
import Works from '../../assets/data/works.json'

function OurWork ()  {

	return(
		<div className="our-work-container">
				<div className="our-work-content">
					<div id="square-container">
							{Works.map((work)=>{
								return(
									<div className="square">
										<img src={work.img} alt=''/> 
										<h10>{work.name}</h10>
									</div>
									)
								} 	
							)}
					</div>	
					</div>
					<div className="work-title">
						<hr/>
						<h3>OUR WORK</h3>
						<hr/>
					</div>
				</div>
	)
}


export default OurWork